html,
body {
  width: 100%;
  height: 100%;

  color: white;
}

body {
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, rgb(213, 62, 209) fixed;
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, -moz-linear-gradient(75deg, rgba(213, 62, 209, 1) 3%, rgba(155, 146, 21, 1) 39%, rgba(210, 219, 198, 1) 86%, rgba(222, 234, 236, 1) 95%) fixed;
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, -webkit-linear-gradient(75deg, rgba(213, 62, 209, 1) 3%, rgba(155, 146, 21, 1) 39%, rgba(210, 219, 198, 1) 86%, rgba(222, 234, 236, 1) 95%) fixed;
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, linear-gradient(75deg, rgba(213, 62, 209, 1) 3%, rgba(155, 146, 21, 1) 39%, rgba(210, 219, 198, 1) 86%, rgba(222, 234, 236, 1) 95%) fixed;
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, rgb(213, 62, 209) fixed;
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, -moz-linear-gradient(75deg, rgba(213, 62, 209, 1) 3%, rgba(155, 146, 21, 1) 39%, rgba(210, 219, 198, 1) 86%, rgba(222, 234, 236, 1) 95%) fixed;
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, -webkit-linear-gradient(75deg, rgba(213, 62, 209, 1) 3%, rgba(155, 146, 21, 1) 39%, rgba(210, 219, 198, 1) 86%, rgba(222, 234, 236, 1) 95%) fixed;
  background: url('./media/img/IMG_20220410_140304.jpg') no-repeat left fixed, linear-gradient(75deg, rgba(213, 62, 209, 1) 3%, rgba(155, 146, 21, 1) 39%, rgba(210, 219, 198, 1) 86%, rgba(222, 234, 236, 1) 95%) fixed;
  background-position: 10%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d53ed1", endColorstr="#deeaec", GradientType=1);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  font-family: "Muli", sans-serif;
  text-align: center;
}

.countdown {
  font-size: 75px;
  margin: 10px;
  font-family: "Lobster", sans-serif;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

h2 {
  color: white;
}

.countdown-wrapper {
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  width: 35%;
  flex-flow: row wrap;
  background: white;
  border-radius: 10px;
  box-shadow: 4px 4px 12px 2px rgba(black, 0.3);
  padding: 40px 40px 30px;
}

.countdown-item {
  margin: 0 auto;
  color: #333;
  display: flex;
  font-size: 40px;
  justify-content: space-evenly;
  flex-flow: column wrap;
  line-height: 30px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}